'use client';

import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import AppStateProvider from '@/components/AppStateProvider';
import SolanaProvider from '@/components/SolanaProvider';

export default function StateProviders({ children }) {
  return (
    <RecoilRoot>
      <ToastContainer position="top-center" />
      <SolanaProvider>
        <AppStateProvider>{children}</AppStateProvider>
      </SolanaProvider>
    </RecoilRoot>
  );
}
