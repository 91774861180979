'use client';

import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';

// import { } from '@/lib/state';
// import { lamportsToToken } from '@/lib/utils';

export default function AppDataProvider({ children }) {
  const pathname = usePathname();
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  return children;
}
